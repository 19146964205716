import CookiesEuBanner from 'cookies-eu-banner'

const setCookie = (name, value, days = 7, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
};

const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, undefined)
};

document.addEventListener('DOMContentLoaded', () => {
    new CookiesEuBanner(() => {
        if (getCookie('analyticConsent') === undefined) {
            setCookie('analyticConsent', document.getElementsByName("analytic_check")[0].checked, 365);

            if (getCookie('analyticConsent') === 'true') {
                window.location.reload();
            }
        }
    }, true)
});